import styled from "styled-components";
import { pixel } from "../../styles/size";

export const Container = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${pixel(40)};
   
`
export const TitleContainer = styled.div`
    h1 {
        color: var(--green001);
        font-size: ${pixel(48)};
        font-weight: 400;
        
    }
    h5 {
        color: #5E5E5E;
    }
    @media only screen and (max-width: 768px)  {
        h1 {
            color: var(--green001);
            font-size: 24px;
        }
    }
`
export const Content = styled.div`
    padding-top: ${pixel(80)};
    max-width: ${pixel(800)};
    margin-bottom: ${pixel(50)};

    strong {
        font-weight: 500;
        display: inline-block;
    }

    h3, h4 {
        font-weight: 600;
    } 
    h3 {
        padding-top: ${pixel(50)};
        color: var(--green002);
    }
    @media only screen and (max-width: 768px)  {
        max-width: 85%;

        strong {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
`
export const Section = styled.div`
    margin-top: ${pixel(10)};
    p {
        text-align: justify;
    }
    h4 {
        color: var(--green001);
        margin-bottom: ${pixel(10)};
    }
    
`