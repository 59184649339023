import { Container } from "./styles";
import ArrowIcon from "../../assets/icons/arrow.svg";

export function ScrollUpBtn() {
  function scrollUp() {
    window.scroll(0, 0);
  }
  return (
    <Container onClick={scrollUp}>
      <img src={ArrowIcon} alt="" />
    </Container>
  );
}
