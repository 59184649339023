import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root{
    --green001: #086835;
    --green002: #07BC0C;
    --gray001: #F6F5FA;
  }
  ::selection {
    color: #fff;
    background: var(--green002);
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  body, input, textarea, button {
    font-family: 'Poppins';
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  } 
  body{
    background-color: #F6F5FA;

    @media only screen and (max-width: 768px) {
      background-color: #ffffff;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #E9E9E9;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--green001);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--green001);
    }

    @media (max-width: 1080px) {
      ::-webkit-scrollbar {
      width: 4px;
    }
    }

    @media (max-width: 720px) {
      ::-webkit-scrollbar {
        width: 2px;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number]{
      -moz-appearance: textfield;
    }
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
