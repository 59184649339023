import styled from "styled-components";
import { pixel } from "../../styles/size";

export const Container = styled.header`
    width: 100%;
    height: ${pixel(90)};
    border-bottom: ${pixel(2)} solid #DEDEDE;

    display: flex;
    align-items: center;

    @media only screen and (max-width: 768px)  {
        height: 10vh;
    }


    h1 {
        font-size: ${pixel(24)};
        margin-top: ${pixel(10)};
        margin-left: ${pixel(15)};
        color: var(--green001);

        @media only screen and (max-width: 768px)  {
            font-size: 12px;
            margin-left: 5px;
        }
    }

    img {
        width: ${pixel(100)};
        height: ${pixel(60)};
        object-fit: contain;
        margin-left: ${pixel(15)};
       
        @media only screen and (max-width: 768px)  {
            width: 50px;
            height: 50px;
        }
    }
    
    
`;