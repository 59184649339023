import { Container } from "./styles";
import LogoImage from "../../assets/img/logo.svg";

export function Header() {
  return (
    <Container>
      <img src={LogoImage} alt="Autocenter" />
      <h1>Autocenter</h1>
    </Container>
  );
}
