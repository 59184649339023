import { ScrollUpBtn } from "../ScrollUpBtn";
import { Container, TitleContainer, Content, Section } from "./styles";

export function PrivacyPolicyContent() {
  return (
    <Container>
      <TitleContainer>
        <h1>Política de Privacidade</h1>
        <h5>ultima atualização 19/10/2022</h5>
      </TitleContainer>
      <Content>
        <strong>
          Leia nossa Política de privacidade, para um maior entendimento sobre
          como seus dados podem ser utilizados
        </strong>
        <h3>1.1.1 POLÍTICA DE PRIVACIDADE </h3>
        <br />
        <Section>
          <h4>SEÇÃO 1 - INFORMAÇÕES GERAIS:</h4>{" "}
          <p>
            A presente Política de Privacidade contém informações sobre coleta,
            uso, armazenamento, tratamento e proteção dos dados pessoais dos
            usuários e visitantes do OU aplicativo Autocenter APP , com a
            finalidade de demonstrar absoluta transparência quanto ao assunto e
            esclarecer a todos interessados sobre os tipos de dados que são
            coletados, os motivos da coleta e a forma como os usuários podem
            gerenciar ou excluir as suas informações pessoais. O presente
            documento foi elaborado em conformidade com a Lei Geral de Proteção
            de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei
            12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, o documento
            poderá ser atualizado em decorrência de eventual atualização
            normativa, razão pela qual se convida o usuário a consultar
            periodicamente esta seção.
          </p>
        </Section>
        <br />
        <Section>
          <h4>
            SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO
            VISITANTE?
          </h4>
          <p>
            Os dados pessoais do usuário e visitante são recolhidos pela
            plataforma da seguinte forma: • Quando o usuário cria uma
            conta/perfil na plataforma autocenterapp esses dados são os dados de
            identificação básicos, como cadastro usuários, com, e-mail, nome
            completo, cidade de residência e profissão). A partir deles, podemos
            identificar o usuário e o visitante, além de garantir uma maior
            segurança e bem-estar às suas necessidades. Ficam cientes os
            usuários e visitantes de que seu perfil na plataforma estará
            acessível a todos demais usuários e visitantes da plataforma
            autocenterapp. • Quando um usuário e visitante acessa o aplicativo
            autocenterapp as informações sobre interação e acesso são coletadas
            pela empresa para garantir uma melhor experiência ao usuário e
            visitante. Estes dados podem tratar sobre as palavras-chaves
            SEÇÃOutilizadas em uma busca, o compartilhamento de um documento
            específico, comentários, visualizações de páginas, perfis, a URL de
            onde o usuário e visitante provêm, o navegador que utilizam e seus
            IPs de acesso, dentre outras que poderão ser armazenadas e retidas.
            • Por intermédio de terceiro: a plataforma autocenterapp recebe
            dados de terceiros, como Usuarios Finais, e Cnpjs indique de quem a
            plataforma recebe dados, por exemplo, Facebook e Google), quando um
            usuário faz login com o seu perfil de um desses sites. A utilização
            desses dados é autorizada previamente pelos usuários junto ao
            terceiro em questão. • Outras
          </p>
        </Section>
        <br />
        <Section>
          <h4>
            {" "}
            SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E
            VISITANTE?
          </h4>
          <p>
            Os dados pessoais do usuário e visitante recolhidos são os
            seguintes: • Dados para a criação da conta/perfil na plataforma
            (exemplo: e-mail, nome completo, cidade de residência e profissão).
            • Dados para otimização da navegação: www.autocenterapp.com.br
            acesso a páginas, palavras-chave utilizadas na busca, recomendações,
            comentários, interação com outros perfis e usuários, perfis
            seguidos, endereço de IP). • Dados para concretizar transações:
            dados referentes ao pagamento e transações, tais como, número do
            cartão de crédito e outras informações sobre o cartão, além dos
            pagamentos efetuados. • Newsletter: o e-mail cadastrado pelo
            visitante que optar por se inscrever na Newsletter será coletado e
            armazenado até que o usuário solicite o descadastro. • Dados
            relacionados a contratos: diante da formalização do contrato de
            compra e venda ou de prestação de serviços entre a plataforma e o
            usuário e visitante poderão ser coletados e armazenados dados
            relativos a execução contratual, inclusive as comunicações realizada
            entre a empresa e o usuário. • Outras •
          </p>
        </Section>
        <br />
        <Section>
          <h4>
            SEÇÃO 3 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO
            USUÁRIO E VISITANTE?
          </h4>{" "}
          <p>
            Os dados pessoais do usuário e do visitante coletados e armazenados
            pela plataforma autocenterapp tem por finalidade: • Bem-estar do
            usuário e visitante: aprimorar o produto e/ou serviço oferecido,
            facilitar, agilizar e cumprir os compromissos estabelecidos entre o
            usuário e a empresa, melhorar a experiência dos usuários e fornecer
            funcionalidades específicas a depender das características básicas
            do usuário. • Melhorias da plataforma: compreender como o usuário
            utiliza os serviços da plataforma, para ajudar no desenvolvimento de
            negócios e técnicas. • Anúncios: apresentar anúncios personalizados
            para o usuário com base nos dados fornecidos. • Comercial: os dados
            são usados para personalizar o conteúdo oferecido e gerar subsídio à
            plataforma para a melhora da qualidade no funcionamento dos
            serviços. • Previsão do perfil do usuário: tratamento automatizados
            de dados pessoais para avaliar o uso na plataforma. • Dados de
            cadastro: para permitir o acesso do usuário a determinados conteúdos
            da plataforma, exclusivo para usuários cadastrados • Dados de
            contrato: conferir às partes segurança jurídica e facilitar a
            conclusão do negócio. • Outras O tratamento de dados pessoais para
            finalidades não previstas nesta Política de Privacidade somente
            ocorrerá mediante comunicação prévia ao usuário, de modo que os
            direitos e obrigações aqui previstos permanecem aplicáveis.
          </p>
        </Section>
        <br />
        <Section>
          <h4>
            SEÇÃO 4 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
          </h4>{" "}
          <p>
            Os dados pessoais do usuário e visitante são armazenados pela
            plataforma durante o período necessário para a prestação do serviço
            ou o cumprimento das finalidades previstas no presente documento,
            conforme o disposto no inciso I do artigo 15 da Lei 13.709/18. Os
            dados podem ser removidos ou anonimizados a pedido do usuário,
            excetuando os casos em que a lei oferecer outro tratamento. Ainda,
            os dados pessoais dos usuários apenas podem ser conservados após o
            término de seu tratamento nas seguintes hipóteses previstas no
            artigo 16 da referida lei: I - cumprimento de obrigação legal ou
            regulatória pelo controlador; II - estudo por órgão de pesquisa,
            garantida, sempre que possível, a anonimização dos dados pessoais;
            III - transferência a terceiro, desde que respeitados os requisitos
            de tratamento de dados dispostos nesta Lei; IV - uso exclusivo do
            controlador, vedado seu acesso por terceiro, e desde que
            anonimizados os dados.
          </p>
        </Section>
        <br />
        <Section>
          <h4>SEÇÃO 5 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</h4>{" "}
          <p>
            A plataforma se compromete a aplicar as medidas técnicas e
            organizativas aptas a proteger os dados pessoais de acessos não
            autorizados e de situações de destruição, perda, alteração,
            comunicação ou difusão de tais dados. Os dados relativas a cartões
            de crédito são criptografados usando a tecnologia "secure socket
            layer" (SSL) que garante a transmissão de dados de forma segura e
            confidencial, de modo que a transmissão dos dados entre o servidor e
            o usuário ocorre de maneira cifrada e encriptada. A plataforma não
            se exime de responsabilidade por culpa exclusiva de terceiro, como
            em caso de ataque de hackers ou crackers, ou culpa exclusiva do
            usuário, como no caso em que ele mesmo transfere seus dados a
            terceiros. O site se compromete a comunicar o usuário em caso de
            alguma violação de segurança dos seus dados pessoais. Os dados
            pessoais armazenados são tratados com confidencialidade, dentro dos
            limites legais. No entanto, podemos divulgar suas informações
            pessoais caso sejamos obrigados pela lei para fazê-lo ou se você
            violar nossos Termos de Serviço.
          </p>
        </Section>
        <br />
        <Section>
          <h4>SEÇÃO 6 - COMPARTILHAMENTO DOS DADOS</h4>{" "}
          <p>
            O compartilhamento de dados do usuário ocorre apenas com os dados
            referentes a publicações realizadas pelo próprio usuário, tais ações
            são compartilhadas publicamente com os outros usuários. Os dados do
            perfil do usuário são compartilhados publicamente em sistemas de
            busca e dentro da plataforma, sendo permitido ao usuário modificar
            tal configuração para que seu perfil não apareça nos resultados de
            busca de tais ferramentas. SEÇÃO 6 - OS DADOS PESSOAIS ARMAZENADOS
            SERÃO TRANSFERIDOS A TERCEIROS? Os dados pessoais (não) podem ser
            compartilhados (com terceiros) (com as seguintes pessoas (físicas)
            (jurídicas): Autocenterapp Ltda, CPF/CNPJ 13.799.849/0001-19. Os
            terceiros indicados recebem os dados na medida do necessário para
            permitir que eles realizem os serviços contratados. Com relação aos
            fornecedores de serviços terceirizados como processadores de
            transação de pagamento, informamos que cada qual tem sua própria
            política de privacidade. Desse modo, recomendamos a leitura das suas
            políticas de privacidade para compreensão de quais informações
            pessoais serão usadas por esses fornecedores.
          </p>
        </Section>
        <br />
        <Section>
          <h4>SEÇÃO 07 – COOKIES OU DADOS DE NAVEGAÇÃO</h4>{" "}
          <p>
            Os cookies referem-se a arquivos de texto enviados pela plataforma
            ao computador do usuário e visitante e que nele ficam armazenados,
            com informações relacionadas à navegação no site. Tais informações
            são relacionadas aos dados de acesso como local e horário de acesso
            e são armazenadas pelo navegador do usuário e visitante para que o
            servidor da plataforma possa lê-las posteriormente a fim de
            personalizar os serviços da plataforma. O usuário e o visitante da
            plataforma Autocenterapp manifesta conhecer e aceitar que pode ser
            utilizado um sistema de coleta de dados de navegação mediante à
            utilização de cookies. O cookie persistente permanece no disco
            rígido do usuário e visitante depois que o navegador é fechado e
            será usado pelo navegador em visitas subsequentes ao site. Os
            cookies persistentes podem ser removidos seguindo as instruções do
            seu navegador. Já o cookie de sessão é temporário e desaparece
            depois que o navegador é fechado. É possível redefinir seu navegador
            da web para recusar todos os cookies, porém alguns recursos da
            plataforma podem não funcionar corretamente se a capacidade de
            aceitar cookies estiver desabilitada.
          </p>
        </Section>
        <br />
        <Section>
          <h4>SEÇÃO 8 – CONSENTIMENTO </h4>
          <p>
            Ao utilizar os serviços e fornecer as informações pessoais na
            plataforma, o usuário está consentindo com a presente Política de
            Privacidade. O usuário, ao cadastrar-se, manifesta conhecer e pode
            exercitar seus direitos de cancelar seu cadastro, acessar e
            atualizar seus dados pessoais e garante a veracidade das informações
            por ele disponibilizadas. O usuário tem direito de retirar o seu
            consentimento a qualquer tempo, para tanto deve entrar em contato
            através do e-mail:
          </p>
        </Section>
        <br />
        <Section>
          <h4>SEÇÃO 9 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h4>{" "}
          <p>
            Reservamos o direito de modificar essa Política de Privacidade a
            qualquer momento, então, é recomendável que o usuário e visitante
            revise-a com frequência. As alterações e esclarecimentos vão surtir
            efeito imediatamente após sua publicação na plataforma. Quando
            realizadas alterações os usuários serão notificados. Ao utilizar o
            serviço ou fornecer informações pessoais após eventuais
            modificações, o usuário e visitante demonstra sua concordância com
            as novas normas. Diante da fusão ou venda da plataforma à outra
            empresa os dados dos usuários podem ser transferidas para os novos
            proprietários para que a permanência dos serviços oferecidos.
          </p>
        </Section>
      </Content>
      <ScrollUpBtn />
    </Container>
  );
}
