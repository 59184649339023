import styled from "styled-components";
import { pixel } from "../../styles/size";

export const Container = styled.button`
    cursor: pointer;
    width: ${pixel(75)};
    height: ${pixel(75)};
    border: 0;
    background-color: var(--green001);
    border-radius: 50%;
    position: sticky;
    left: calc(100% - ${pixel(90)});
    bottom: ${pixel(30)};

    :hover,
    :active {
        filter: brightness(0.9);
    }
    @media only screen and (max-width: 768px)  {
        width: 50px;
        height: 50px;
        left: calc(100% - 60px);
    }

    img {
        transform: scale(1.1);
    }
`