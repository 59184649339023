import { Header } from "./components/header";
import { PrivacyPolicyContent } from "./components/PrivacyPolicyContent";
import GlobalStyle from "./styles/global";

function App() {
  return (
    <>
      <Header />
      <PrivacyPolicyContent />
      <GlobalStyle />
    </>
  );
}

export default App;
